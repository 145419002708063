//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
import PageHeader from '@/components/tools/PageHeaderCustom.vue';
import RadioComponent from '@/components/common/RadioComponent.vue';
import { DEFAULT_PORTAL_SETTING, DEFAULT_PAGE_SETTING } from '@/enum';
export default {
  components: {
    PageHeader,
    RadioComponent
  },

  data() {
    return {
      form: this.$form.createForm(this, { name: 'compose-page' }),
      pageTemplate: 'CATALOG',
      loading: false
    };
  },

  computed: {
    ...mapGetters('user', ['userInfo']),
    ...mapGetters('project', ['projectById']),
    formItemLayout() {
      return {
        labelCol: { sm: { span: 8 }, lg: { span: 4 }, xl: { span: 4 } },
        wrapperCol: { sm: { span: 16 }, lg: { span: 16 }, xl: { span: 16 } }
      };
    },
    formTailLayout() {
      return {
        labelCol: { sm: { span: 8 }, lg: { span: 4 }, xl: { span: 4 } },
        wrapperCol: {
          sm: { span: 16, offset: 8 },
          lg: { span: 16, offset: 4 },
          xl: { span: 16, offset: 4 }
        }
      };
    },
    pageSetting() {
      return this.pageTemplate === 'CATALOG' ? DEFAULT_PAGE_SETTING : DEFAULT_PORTAL_SETTING;
    },
    projectId() {
      return this.$route.params.projectId;
    },
    currentProject() {
      return this.projectById(this.projectId);
    }
  },
  methods: {
    ...mapActions('page', ['addPage']),

    createPage() {
      this.form.validateFields(async (err, values) => {
        if (!err) {
          this.loading = true;
          try {
            const data = await this.$s.api.page.createPage(
              this.userInfo.company_id,
              this.projectId,
              {
                name: values.name,
                template: this.pageTemplate,
                thumbnail: this.currentProject?.thumbnail,
                setting_options: this.pageSetting
              }
            );
            this.$notification.success({
              message: this.$tc('Created page successfully.'),
              duration: 3
            });
            this.addPage({ payload: data });
            this.$router.push(`/projects/${this.projectId}/pages`);
          } catch (error) {
            this.$notification.console.error({
              message: error.message,
              duration: 3
            });
          } finally {
            this.loading = false;
          }
        }
      });
    }
  }
};
