var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c("page-header", {
        attrs: { title: _vm.$t("Compose page title"), "show-back-icon": "" },
        on: {
          back: function() {
            return _vm.$router.go(-1)
          }
        }
      }),
      _c(
        "a-card",
        { staticClass: "px-xl", staticStyle: { "margin-top": "12px" } },
        [
          _c(
            "div",
            {
              staticClass: "font-bold mb-md",
              staticStyle: {
                "font-size": "24px",
                "line-height": "2rem/* 32px */",
                "border-bottom": "1px solid #ccc",
                color: "#686868"
              }
            },
            [_vm._v(" " + _vm._s(_vm.$t("Basic information")) + " ")]
          ),
          _c(
            "a-form",
            {
              attrs: { form: _vm.form, align: "middle" },
              on: {
                submit: function($event) {
                  $event.stopPropagation()
                  $event.preventDefault()
                  return _vm.createPage($event)
                }
              }
            },
            [
              _c(
                "a-form-item",
                _vm._b(
                  { attrs: { label: _vm.$t("Page name") } },
                  "a-form-item",
                  _vm.formItemLayout,
                  false
                ),
                [
                  _c("a-input", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "name",
                          {
                            rules: [
                              {
                                required: true,
                                message: _vm.$t("Please input your page name")
                              },
                              {
                                max: 50,
                                message: _vm.$t("Page name max length 50")
                              }
                            ]
                          }
                        ],
                        expression:
                          "[\n            'name',\n            {\n              rules: [\n                { required: true, message: $t('Please input your page name') },\n                { max: 50, message: $t('Page name max length 50') }\n              ]\n            }\n          ]"
                      }
                    ],
                    attrs: {
                      placeholder: _vm.$t("Please input your page name")
                    }
                  })
                ],
                1
              ),
              _c(
                "a-row",
                { attrs: { gutter: [8, 8], type: "flex" } },
                [
                  _c(
                    "a-col",
                    { attrs: { span: 16, offset: 4 } },
                    [
                      _c(
                        "a-row",
                        {
                          staticClass: "mb-xs ",
                          attrs: { type: "flex", justify: "start" }
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "text-gray-500 ant-form-item-required"
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "Select the type of page you want to create. Additional configuration and integrations can be added later."
                                  )
                                )
                              )
                            ]
                          )
                        ]
                      ),
                      _c(
                        "a-row",
                        { attrs: { gutter: [8, 8] } },
                        [
                          _c(
                            "a-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "RadioComponent",
                                {
                                  attrs: {
                                    label: _vm.$t("Catalog"),
                                    value: "CATALOG",
                                    height: "368px"
                                  },
                                  model: {
                                    value: _vm.pageTemplate,
                                    callback: function($$v) {
                                      _vm.pageTemplate = $$v
                                    },
                                    expression: "pageTemplate"
                                  }
                                },
                                [
                                  _c(
                                    "template",
                                    {
                                      staticClass: "description",
                                      slot: "body"
                                    },
                                    [
                                      _c("a-icon", {
                                        staticClass: "mb-xs",
                                        style: { fontSize: "40px" },
                                        attrs: { type: "project" }
                                      }),
                                      _c("p", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "A Catalog page is a collection of codes that can be used to build a website or application. It is a great way to start a project."
                                              )
                                            ) +
                                            " "
                                        )
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                2
                              )
                            ],
                            1
                          ),
                          _c(
                            "a-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "RadioComponent",
                                {
                                  attrs: {
                                    label: _vm.$t("Portal"),
                                    value: "PORTAL",
                                    height: "368px"
                                  },
                                  model: {
                                    value: _vm.pageTemplate,
                                    callback: function($$v) {
                                      _vm.pageTemplate = $$v
                                    },
                                    expression: "pageTemplate"
                                  }
                                },
                                [
                                  _c(
                                    "template",
                                    {
                                      staticClass: "description",
                                      slot: "body"
                                    },
                                    [
                                      _c("a-icon", {
                                        staticClass: "mb-xs",
                                        style: { fontSize: "40px" },
                                        attrs: { type: "deployment-unit" }
                                      }),
                                      _c("p", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "A portal is a collection of pages that can be used to build a website or application."
                                              )
                                            ) +
                                            " "
                                        )
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                2
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-form-item",
                {
                  attrs: { wrapperCol: { span: 4, offset: 10 }, colon: false }
                },
                [
                  _c(
                    "a-button",
                    {
                      attrs: {
                        loading: _vm.loading,
                        type: "primary",
                        block: "",
                        "html-type": "submit"
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("Compose page")) + " ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }